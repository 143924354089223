import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const Dashboard = Loadable(lazy(() => import('views/teacher/dashboard/Dashboard')));
const TeacherProfile = Loadable(lazy(() => import('views/teacher/profile')));
const AttendanceReport = Loadable(lazy(() => import('views/teacher/attendance')));
const MyLeave = Loadable(lazy(() => import('views/teacher/leave/my-leave/MyLeave')));
const ApproveLeave = Loadable(lazy(() => import('views/teacher/leave/LeaveApprove/LeaveApproval')));
const TeacherStudentList = Loadable(lazy(() => import('views/teacher/dashboard/StudentList')));
const HodDashboard = Loadable(lazy(() => import('views/hod/HodDashboard')));
const AnnouncementApproval = Loadable(lazy(() => import('views/hod/AnnouncementApproval')));
const LessonPlanApprovalRequested = Loadable(lazy(() => import('views/application/lessionPlan/LessonPlanRequestedList')));
const LearningResources = Loadable(lazy(() => import('views/teacher/resources/learning-resources')));
const TeachingResources = Loadable(lazy(() => import('views/teacher/resources/teaching-resources')));
const AccountantDashBoard = Loadable(lazy(() => import('views/dashboard/accountent/Dashboard')));
const RegistrarDashBoard = Loadable(lazy(() => import('views/registrardashboard/RegistrarDashboard')));

const LeaveReport = Loadable(lazy(() => import('views/teacher/leave/LeaveReport')));
const EmployeePaySlips = Loadable(lazy(() => import('views/teacher/payslips')));

const LeaveListByType = Loadable(lazy(() => import('views/teacher/dashboard/LeaveListbyType')));
const DeanDashboard = Loadable(lazy(() => import('views/dashboard/dean/DeanDashboard')));
const DUCDashboard = Loadable(lazy(() => import('views/dashboard/duc/DUCDashboard')));
const TotalStaffList = Loadable(lazy(() => import('views/hod/TotalStaffList')));
const TodaysStaffAttandance = Loadable(lazy(() => import('views/hod/StaffAttandance')));

const roles = ['ROLE_COLLEGE_SUB_ADMIN', 'ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN'];

const TeacherRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/teacher/dashboard',
            element: <Dashboard />
        },
        {
            path: '/teacher/profile',
            element: <TeacherProfile />
        },
        {
            path: '/teacher-profile/:staffId',
            element: <TeacherProfile />
        },
        {
            path: '/faculty-attendance',
            element: <AttendanceReport />
        },
        {
            path: '/teacher/leaves',
            element: <MyLeave />
        },
        {
            path: '/teacher/leaves/approve',
            element: <ApproveLeave />
        },
        {
            path: '/teacher/studentlist',
            element: <TeacherStudentList />
        },
        {
            path: '/leave/report',
            element: <LeaveReport />
        },
        {
            path: '/hod/dashboard',
            element: <HodDashboard />
        },
        {
            path: '/dean/dashboard',
            element: <DeanDashboard />
        },
        {
            path: '/duc/dashboard',
            element: <DUCDashboard />
        },
        {
            path: '/accountant/dashboard',
            element: <AccountantDashBoard />
        },
        {
            path: '/announcement/approval',
            element: <AnnouncementApproval />
        },
        {
            path: '/lesson/plan/approval',
            element: <LessonPlanApprovalRequested />
        },
        {
            path: '/teaching-resources',
            element: <TeachingResources />
        },
        {
            path: '/learning-resources',
            element: <LearningResources />
        },
        {
            path: '/registrar/dashboard',
            element: <RegistrarDashBoard />
        },
        {
            path: '/leavelist/bytype',
            element: <LeaveListByType />
        },
        {
            path: '/employee/payslips',
            element: <EmployeePaySlips />
        },
        {
            path: '/total/staff/list/:departmentId',
            element: <TotalStaffList />
        },
        {
            path: '/todays/staff/attandance/:departmentId',
            element: <TodaysStaffAttandance />
        }
    ]
};
export default TeacherRoutes;
