import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';
import ViewStaffResponse from 'views/staff-appraisal/staff-response/ViewStaffResponse';

const AppraisalForm = Loadable(lazy(() => import('views/staff-appraisal/appraisal-form')));
const AppraisalQuestions = Loadable(lazy(() => import('views/staff-appraisal/appraisal-questions')));
const StaffAppraisalForm = Loadable(lazy(() => import('views/staff-appraisal/staff-response/StaffAppraisalForm')));
const ResponseListToAdmin = Loadable(lazy(() => import('views/staff-appraisal/staff-response/ResponseListToAdmin')));
const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const StaffAppraisalRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),

    children: [
        {
            path: '/appraisal/staff',
            element: <AppraisalForm />
        },
        {
            path: '/appraisal/questions',
            element: <AppraisalQuestions />
        },
        {
            path: '/appraisal/form',
            element: <StaffAppraisalForm />
        },
        {
            path: '/appraisal/responses/list',
            element: <ResponseListToAdmin />
        },
        {
            path: '/appraisal/staff-response',
            element: <ViewStaffResponse />
        }
    ]
};

export default StaffAppraisalRoutes;
