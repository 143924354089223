import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// Department
const AdmissionList = Loadable(lazy(() => import('views/admission/admission-list/index')));
const StudentProfile = Loadable(lazy(() => import('views/admission/admission-list/StudentProfile')));
const EntranceExams = Loadable(lazy(() => import('views/entranceexam/index')));
const HallTicket = Loadable(lazy(() => import('views/hallticketlist/index')));
const MeritList = Loadable(lazy(() => import('views/MeritList/index')));
const ShortListedList = Loadable(lazy(() => import('views/MeritList/ShortListedList')));
const AdmissionPayment = Loadable(lazy(() => import('views/studentadmissionProfile/AdmissionPayment')));
const InternalAssessment = Loadable(lazy(() => import('views/examAssement/index')));
const EditInternalAssessment = Loadable(lazy(() => import('views/examAssement/AssessmentEdit')));
const IASubjectAllocation = Loadable(lazy(() => import('views/iaSubjectAllocation/index')));
const InternalAssessmentMarksGroup = Loadable(lazy(() => import('views/examAssement/InternalAssessmentMarksGroup')));
const EditIASubjectAllocation = Loadable(lazy(() => import('views/iaSubjectAllocation/EditIaSubjectAllocation')));
const SubjectiveObjectiveGroup = Loadable(lazy(() => import('views/subjectiveobjectivegroup/index')));
const DistanceEducationAdmission = Loadable(lazy(() => import('views/offlinedistanceadmission/FetchStudentData')));
const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const AdmissionRouts = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // {
        //     path: '/admission/list',
        //     element: <AdmissionList />
        // },
        {
            path: '/student/registration/list',
            element: <AdmissionList />
        },
        {
            path: '/admission/student/profiile/:id',
            element: <StudentProfile />
        },
        {
            path: '/admission/entranceexam',
            element: <EntranceExams />
        },
        {
            path: '/distance/education/admission',
            element: <DistanceEducationAdmission />
        },
        {
            path: '/admission/entranceexam/hallticket/:id/:courseName',
            element: <HallTicket />
        },
        {
            path: '/admission/entranceexam/meritlist/:id/:courseName',
            element: <MeritList />
        },
        {
            path: '/admission/entranceexam/shortlisted/list/:id',
            element: <ShortListedList />
        },
        {
            path: '/admission/payment',
            element: <AdmissionPayment />
        },
        {
            path: '/internal/assessment/marks/group',
            element: <InternalAssessmentMarksGroup />
        },
        {
            path: '/subjective/objective/group',
            element: <SubjectiveObjectiveGroup />
        },
        {
            path: '/internal/assessment',
            element: <InternalAssessment />
        },
        {
            path: '/internal/assessment/edit/:id',
            element: <EditInternalAssessment />
        },
        {
            path: '/ia/subjects/allocation',
            element: <IASubjectAllocation />
        },
        {
            path: '/edit/ia/subjects/allocation/:id',
            element: <EditIASubjectAllocation />
        }
    ]
};

export default AdmissionRouts;
