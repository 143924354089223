/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from '@mui/material';
import { useParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import axios from 'axios';
import { DataGrid, GridToolbar, GridToolbarExport, GridToolbarContainer } from '@mui/x-data-grid';
import { gridSpacing } from 'store/constant';
import { useTheme } from '@mui/material/styles';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import UpdateJoinDateDialog from './UpdateJoinDateDialog';
import moment from 'moment';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DiscontinueHostel from './DiscontinueHostel';

const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarExport />
    </GridToolbarContainer>
);
// `${row?.row?.floor === 1 ? 'Ground' : row?.row?.floor - 1} floor`

const StudentDetails = () => {
    const [buildingList, setBuildingList] = useState([]);
    const [buildingId, setBuildingId] = useState('');
    const [floorNumbers, setFloorNumbers] = useState([]);
    const [roomList, setRoomList] = useState([]);
    const [roomID, setRoomID] = useState('');
    const [isFloorSelected, setIsFloorSelected] = useState(false);
    const [alignment, setAlignment] = useState('right');
    const [isStudentListFetched, setIsStudentListFetched] = useState(false);
    const [studentList, setStudentList] = useState([]);
    const [isBuildingChanging, setIsBuildingChanging] = useState(false);
    const [dateType, setDateType] = useState('');
    const [formDate, setFormDate] = useState('');
    const [toDate, settoDate] = useState('');
    const [floorsNumber, setfloorsNumber] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    console.log(floorsNumber, 'floorsNumber');
    const [getDate, setgetDate] = useState('');
    const [open, setOpen] = useState(false);
    const [student, setStudent] = useState();
    const [isDiscontinue, setIsDiscontinue] = useState(false);
    const [rollNumber, setRollNumber] = useState('');
    const [discontinue, setDiscontinue] = useState(false);

    const dateUpdateDialog = (data) => {
        setgetDate(data);
        setOpen(true);
    };
    const handleCloseD = () => {
        setOpen(false);
    };

    const { floorNumber, room } = useParams();
    const buildingID = useParams().buildingId;
    console.log(floorNumber, room, buildingID, 'buildingID');
    const data = {
        buildingID: buildingId,
        floor: floorsNumber,
        rooms: roomID
    };
    const rowFlatter = (row) => {
        // console.log(row, 'rowFlatter');
        const flatten = row.map((item, index) => ({
            ...item,
            id: item?.id
        }));
        // console.log(flatten, 'flatten');
        return flatten;
    };

    const paramsFetchStudentsDetails = async () => {
        console.log(floorsNumber, 'floorsNumber');
        const studentList = await axios.get(
            `/v1/hostel/students/filter?/v1/hostel/rooms/available-beds/by-floor?buildingId=${buildingId}&floor=${
                floorNumber === undefined ? floorsNumber : floorNumber
            }&roomId=${roomID}&fromDate=${formDate}&toDate=${toDate}`,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        setIsStudentListFetched(true);
        const flatten = rowFlatter(studentList.data.StaffDetailList);
        setStudentList(flatten);
    };

    const fetchAllBuildings = async () => {
        const buildings = await axios.get(`/v1/hostel/buildings/list`, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                'Content-Type': 'application/json'
            }
        });
        const list = buildings.data.hostelBuildingList?.filter((elem) => elem?.status);
        setBuildingList(list);
        if (buildings.status === 200) {
            if (buildingID) {
                const buildingArray = buildings.data.hostelBuildingList?.filter((building) => building.id === buildingID);
                const buidlingObj = buildingArray[0];
                setBuildingId(buidlingObj?.id);
                setFloorNumbers(buidlingObj?.floors);
            }
        }
    };

    // const handleBuildingSelection = (id) => {
    //     if (id !== '') {
    //         setIsBuildingChanging(true);
    //         const buildingArray = buildingList?.filter((building) => building.id === id);
    //         console.log(buildingList, 'buildingArray');
    //         const buidlingObj = buildingArray[0];
    //         setBuildingId(buidlingObj?.id);
    //         setFloorNumbers(buidlingObj?.floors);
    //         console.log(buidlingObj, 'buidlingObj');
    //     }

    //     if (floorNumber) {
    //         handleFloorSelection(floorNumber);
    //     }
    // };
    const handleBuildingSelection = (id) => {
        if (id !== '') {
            setIsBuildingChanging(true);
            const buildingArray = buildingList?.filter((building) => building.id === id);
            const buildingObj = buildingArray[0];
            setBuildingId(buildingObj?.id);
            setFloorNumbers(buildingObj?.floors);

            // Clear floor and room selections
            setfloorsNumber('');
            setRoomList([]);
            setRoomID('');
            setIsFloorSelected(false);
        } else {
            // Clear all selections when no building is selected
            setBuildingId('');
            setFloorNumbers([]);
            setfloorsNumber('');
            setRoomList([]);
            setRoomID('');
            setIsFloorSelected(false);
        }

        setIsBuildingChanging(false);
    };

    const handleFloorSelection = async (floorNumber) => {
        console.log(floorsNumber, 'floorsNumber');
        setfloorsNumber(floorNumber);
        const floorData = await axios.get(
            `/v1/hostel/rooms/available-beds/by-floor?buildingId=${buildingId}&floor=${
                floorNumber === undefined ? floorsNumber : floorNumber
            }&roomId=${roomID}&fromDate=${formDate}&toDate=${toDate}`,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        setRoomList(floorData.data.roomsList);
    };

    const handleRoomSelection = (roomId) => {
        setRoomID(roomId);
        if (roomId !== '') {
            setIsFloorSelected(true);
        } else {
            setIsFloorSelected(false);
        }
    };

    const fetchStudentsDetails = async () => {
        setIsSubmitting(true);
        const studentList = await axios.get(
            `/v1/hostel/students/filter?buildingId=${buildingId}&floor=${
                floorNumber === undefined ? floorsNumber : floorNumber
            }&roomId=${roomID}&fromDate=${formDate}&toDate=${toDate}&rollNo=${rollNumber.trim()}&discontinued=${discontinue}&dateType=${dateType}`,
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        setIsStudentListFetched(true);
        console.log(studentList, 'studentList');
        // if (studentList.data.hostelStudents > 0) {
        //     const flatten = rowFlatter(studentList.data.hostelStudents);
        //     setStudentList(flatten);
        //     setIsSubmitting(false);
        // } else {
        //     dispatch(
        //         openSnackbar({
        //             open: true,
        //             message: 'Student not found',
        //             variant: 'alert',
        //             alert: {
        //                 color: 'error'
        //             },
        //             close: false
        //         })
        //     );
        //     setIsSubmitting(false);
        // }
        const flatten = rowFlatter(studentList.data.hostelStudents);
        setStudentList(flatten);
        setIsSubmitting(false);
    };

    useEffect(() => {
        fetchAllBuildings();
    }, []);

    useEffect(() => {
        if (floorNumber && buildingId && !isBuildingChanging) {
            handleFloorSelection(floorNumber);
        }
    }, [floorNumber, buildingId, isBuildingChanging]);

    useEffect(() => {
        if (room) {
            paramsFetchStudentsDetails();
        }
    }, [room]);

    const getDateType = (dateType) => {
        setDateType(dateType);
    };

    const handleChangeFromDate = (fromDate) => {
        setFormDate(fromDate);
    };
    const handleChangeToDate = (toDate) => {
        settoDate(toDate);
    };

    // const handleGetDiscontinue = (date) => {
    //     setDate(date);
    // };

    const handleHostelCancel = (student) => {
        setStudent(student);
        setIsDiscontinue(true);
    };
    const handleClose = () => {
        setIsDiscontinue(false);
    };
    const theme = useTheme();
    const prepredColumn = [
        {
            field: 'Action',
            headerName: 'Action',
            width: 150,
            renderCell: (row) => (
                <div>
                    <Tooltip title="Update Join Date">
                        <IconButton
                            color="primary"
                            onClick={() => {
                                dateUpdateDialog({ joinDate: row.row.joinedDate, studentId: row.row.studentId });
                            }}
                        >
                            <CalendarTodayIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Discontinue Hostel">
                        <IconButton
                            // color="primary"
                            style={{ color: 'red' }}
                            onClick={() => {
                                handleHostelCancel({ studentId: row.row.studentId, studentName: row?.row?.studentMini?.studentName });
                            }}
                        >
                            <DoNotDisturbIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        },
        { field: 'studentName', headerName: 'Student Name', width: 200, valueGetter: ({ row }) => row.studentMini.studentName },
        { field: 'rollNo', headerName: 'Roll Number', width: 200, valueGetter: ({ row }) => row.studentMini.rollNo },
        {
            field: 'joinDate',
            headerName: 'Join Date',
            width: 150,
            valueGetter: ({ row }) => moment(row.joinedDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        },
        { field: 'semester', headerName: 'Semester', width: 120, valueGetter: ({ row }) => row.studentMini.semester },
        { field: 'buidling', headerName: 'Building', width: 180, valueGetter: ({ row }) => row.room.building.name },
        {
            field: 'hostelRoomCategory',
            headerName: 'Room Category',
            width: 180,
            valueGetter: ({ row }) => row.room.hostelRoomCategory.name
        },
        { field: 'room', headerName: 'Room', width: 150, valueGetter: ({ row }) => row.room.name },
        {
            field: 'floor',
            headerName: 'Floor',
            width: 150,
            valueGetter: ({ row }) => `${row?.floor === 1 ? 'Ground' : row?.floor - 1} floor`
        },
        { field: 'courseType', headerName: 'Course Type', width: 200, valueGetter: ({ row }) => row.studentMini.courseType },
        { field: 'status', headerName: 'Discontinue', width: 200, valueGetter: ({ row }) => (row?.status === false ? 'Yes' : 'No') }
    ];

    const getRollNumber = (rollNo) => {
        setRollNumber(rollNo);
    };

    const getDiscontinueData = (discontinue) => {
        setDiscontinue(discontinue);
    };
    return (
        <>
            <MainCard sx={{ mt: 1 }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="Country-select">Select Building</InputLabel>
                            <Select
                                fullWidth
                                id="buildings"
                                name="buildings"
                                label="Select Buildings *"
                                defaultValue={buildingID}
                                required
                                onChange={(event) => handleBuildingSelection(event.target.value)}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {buildingList.map((building) => (
                                    <MenuItem value={building.id}>{building.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="Country-select">Select Floor</InputLabel>
                            <Select
                                fullWidth
                                id="Floors"
                                name="Floors"
                                label="Select Floors *"
                                defaultValue={isBuildingChanging ? '' : floorNumber}
                                value={floorsNumber}
                                required
                                onChange={(event) => handleFloorSelection(event.target.value)}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {Array.from({ length: floorNumbers }, (_, i) => (
                                    <MenuItem value={i + 1}>{`${i === 0 ? 'Ground' : i} Floor`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="Country-select">Select Room</InputLabel>
                            <Select
                                fullWidth
                                id="rooms"
                                name="rooms"
                                label="Select Rooms *"
                                defaultValue={room}
                                value={roomID}
                                required
                                onChange={(event) => handleRoomSelection(event.target.value)}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {roomList.map((room) => (
                                    <MenuItem value={room.room.id}>{`${room.room.name} (${room.room.hostelRoomCategory.name})`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="Country-select">Date Type</InputLabel>
                            <Select
                                fullWidth
                                // id="rooms"
                                // name="rooms"
                                label="Rooms *"
                                // defaultValue={room}
                                required
                                onChange={(event) => getDateType(event.target.value)}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="allocatedDate">Allocated Date</MenuItem>
                                <MenuItem value="joinedDate">Joined Date</MenuItem>
                                <MenuItem value="vacatingDate">Vacating Date</MenuItem>
                                {/* {roomList.map((room) => (
                                    <MenuItem value={room.room.id}>{`${room.room.name} (${room.room.hostelRoomCategory.name})`}</MenuItem>
                                ))} */}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="Country-select">Status</InputLabel>
                            <Select
                                fullWidth
                                // id="rooms"
                                // name="rooms"
                                label="Status"
                                // defaultValue={room}
                                required
                                onChange={(event) => getDiscontinueData(event.target.value)}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="false">Continue</MenuItem>
                                <MenuItem value="true">Discontinue</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="Roll No"
                            onChange={(event) => {
                                getRollNumber(event.target.value);
                            }}
                        />
                    </Grid>
                    {dateType === 'allocatedDate' && (
                        <>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    type="date"
                                    id="fromDate"
                                    name="fromDate"
                                    label="From Date*"
                                    onChange={(e) => {
                                        handleChangeFromDate(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    type="date"
                                    id="toDate"
                                    name="toDate"
                                    label="To Date*"
                                    onChange={(e) => {
                                        handleChangeToDate(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )}
                    {dateType === 'joinedDate' && (
                        <>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    type="date"
                                    id="fromDate"
                                    name="fromDate"
                                    label="From Date*"
                                    onChange={(e) => {
                                        handleChangeFromDate(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    type="date"
                                    id="toDate"
                                    name="toDate"
                                    label="To Date*"
                                    onChange={(e) => {
                                        handleChangeToDate(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )}
                    {dateType === 'vacatingDate' && (
                        <>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    type="date"
                                    id="fromDate"
                                    name="fromDate"
                                    label="From Date*"
                                    onChange={(e) => {
                                        handleChangeFromDate(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    type="date"
                                    id="toDate"
                                    name="toDate"
                                    label="To Date*"
                                    onChange={(e) => {
                                        handleChangeToDate(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item md={12} sx={{ display: 'flex', justifyContent: alignment }}>
                        <Button
                            variant="contained"
                            onClick={fetchStudentsDetails}
                            startIcon={isSubmitting ? <CircularProgress size="1rem" sx={{ color: 'red' }} /> : undefined}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Please Wait' : 'Search'}
                        </Button>
                    </Grid>
                </Grid>
            </MainCard>
            {open && (
                <UpdateJoinDateDialog
                    open={open}
                    handleCloseD={handleCloseD}
                    data={getDate}
                    fetchData={data}
                    fetchStudentsDetails={fetchStudentsDetails}
                />
            )}
            {isDiscontinue && (
                <DiscontinueHostel
                    open={isDiscontinue}
                    handleClose={handleClose}
                    student={student}
                    fetchStudentsDetails={fetchStudentsDetails}
                />
            )}
            <MainCard>
                <Box
                    sx={{
                        height: 600,
                        width: '100%',
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            '& .MuiDataGrid-cell': {
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                            },
                            '& .MuiDataGrid-columnsContainer': {
                                color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900',
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                            },
                            '& .MuiDataGrid-columnSeparator': {
                                color: theme.palette.mode === 'dark' ? theme.palette.text.primary + 15 : 'grey.200'
                            },

                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                                py: '25px'
                            },
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                                py: '15px'
                            },
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                                py: '22px'
                            }
                        }
                    }}
                >
                    <DataGrid
                        rows={studentList}
                        components={{ Toolbar: CustomToolbar }}
                        getRowId={(row) => row.id}
                        columns={prepredColumn}
                        getRowHeight={() => 'auto'}
                        slots={{ toolbar: GridToolbar }}
                    />
                </Box>
            </MainCard>
        </>
    );
};

export default StudentDetails;
