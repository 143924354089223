/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable operator-assignment */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItemButton,
    ListItemText,
    MenuItem,
    Select,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import DashboardSideImage from 'ui-component/cards/DashboardSideImage';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import axios from 'axios';
import RoomCard from './RoomCard';
import { gridSpacing } from 'store/constant';
import { Stack } from '@mui/system';
import HostelReports from './reports';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

const Dashboard = () => {
    const [value, setValue] = useState('1');
    const [buildingId, setBuildingId] = useState('');
    const [building, setBuilding] = useState(null);
    const [floorNumber, setFloorNumber] = useState(0);
    const [isBuildingSelected, setIsBuildingSelected] = useState(false);
    const [floorData, setFloorData] = useState([]);
    const [currentFloor, setCurrentFloor] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [reportData, setReportData] = useState();

    const [hostelStats, setHostelStats] = useState({});
    const [categoryStats, setCategoryStats] = useState([]);

    const [hostelBuildingsList, setHostelBuildingsList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [maleCount, setMaleCount] = useState(0);
    const [femaleCount, setFemaleCount] = useState(0);

    const errorSX = { color: 'error.main' };
    const successSX = { color: 'success.dark' };
    const theme = useTheme();
    const navigate = useNavigate();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const handleFloorChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchHostelBuildings = async () => {
        const buildings = await axios.get(`/v1/hostel/buildings/list`, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                'Content-Type': 'application/json'
            }
        });
        const list = buildings.data.hostelBuildingList?.filter((elem) => elem?.status);
        setHostelBuildingsList(list);
    };

    const handleBuildingSelection = (event) => {
        const id = event.target.value;
        setBuildingId(id);
        setIsLoading(false);
        fetchHostelCategoryByHostel(id);
        if (id !== '') {
            setIsBuildingSelected(true);
            const buildingObj = hostelBuildingsList.filter((building) => building.id === id);
            setBuilding(buildingObj[0]);
            setFloorNumber(buildingObj[0].floors);
            fetchFloorData(currentFloor, id);
        } else {
            setIsBuildingSelected(false);
            handleGetAll();
        }
    };

    const fetchFloorData = async (i, id) => {
        const floorData = await axios.get(`/v1/hostel/rooms/available-beds/by-floor?buildingId=${id ?? buildingId}&floor=${i}`, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                'Content-Type': 'application/json'
            }
        });
        setFloorData(floorData?.data?.roomsList);
    };

    const fetchFloorDataPerCategory = async (floorNumber, category) => {
        const floorData = await axios.get(`/v1/hostel/rooms/available-beds/by-floor?buildingId=${buildingId}&floor=${floorNumber}`, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                'Content-Type': 'application/json'
            }
        });
        if (floorData.status === 200) {
            const filtered = floorData.data.roomsList.filter((data) => data.room.hostelRoomCategory.name === category);
            setFloorData(filtered);
        }
    };

    const fetchHostelCategoryStats = async () => {
        try {
            const response = await axios.get(`/v1/hostel/category/report`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                setCategoryStats(response.data.categoryReport);
                setStatsByBuildingSelection(response.data.categoryReport);
            }
        } catch (error) {
            console.error({ error });
        }
    };

    const hostelGenderCount = async () => {
        try {
            const response = await axios.get(`/v1/hostel/dashboard`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            });
            setReportData(response.data.data);
            setMaleCount(response.data.data.boysCount);
            setFemaleCount(response.data.data.girlsCount);
        } catch (error) {
            console.error({ error });
        }
    };

    const fetchHostelCategoryByHostel = async (hostelId) => {
        try {
            const response = await axios.get(`/v1/hostel/category/report/by-building?buildingId=${hostelId}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                setCategoryStats(response.data.categoryReport);
                setStatsByBuildingSelection(response.data.categoryReport);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const setStatsByBuildingSelection = (buildings) => {
        let capacity = 0;
        let vacancy = 0;

        buildings.map((build, i) => {
            capacity = capacity + build.capacity;
            vacancy = vacancy + build.vacancy;
        });

        setHostelStats({
            totalCapacity: capacity,
            totalVacancy: vacancy
        });
    };

    const handleGetAll = () => {
        fetchHostelCategoryStats();
        fetchHostelBuildings();
    };

    const categorySelection = async (category) => {
        if (buildingId !== '') {
            setSelectedCategory(category);
            fetchFloorDataPerCategory(floorNumber, category);
        }
    };

    useEffect(() => {
        fetchHostelCategoryStats();
        fetchHostelBuildings();
        hostelGenderCount();
    }, []);

    return (
        <Grid>
            <MainCard sx={{ mt: 1 }}>
                <Grid item md={6} sm={6} xs={12} display="flex" alignItems="center">
                    <Tooltip title="Go Back">
                        <IconButton onClick={() => navigate(-1)}>
                            <ArrowBack sx={{ color: '#212429' }} />
                        </IconButton>
                    </Tooltip>
                    <Typography variant="h3">Hostel Information</Typography>
                </Grid>
            </MainCard>
            {matchesXs && (
                <Grid item md={12} lg={12} xs={12} mt={1}>
                    <Button onClick={() => navigate(-1)} variant="contained">
                        Back
                    </Button>
                </Grid>
            )}
            <Grid
                container
                spacing={2}
                item
                xs={12}
                lg={12}
                md={12}
                sm={12}
                style={{
                    borderRadius: '5%'
                }}
                sx={{ mt: 1, mb: 1 }}
            >
                <Grid item lg={4} md={6} sm={10} xs={12}>
                    <DashboardSideImage
                        // primary={hostelStats?.totalCapacity}
                        primary={reportData?.totalCapacity}
                        secondarySub="Total Capacity"
                        color="#015ca1"
                        bgImage={<img src="/assets/images/icons/TotalCapacity.svg" alt="Notification" />}
                    />
                </Grid>
                <Grid item lg={4} md={6} sm={10} xs={12}>
                    <DashboardSideImage
                        primary={reportData?.totalCapacity - reportData?.totalVacancy}
                        secondarySub="Total Occupied"
                        color="#015ca1"
                        bgImage={<img src="/assets/images/icons/TotalOcupies.svg" alt="Notification" />}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={10} xs={12}>
                    <DashboardSideImage
                        primary={reportData?.totalVacancy}
                        secondarySub="Total Vacancy"
                        color="#015ca1"
                        navigateUrl="/hostel/reports"
                        bgImage={<img src="/assets/images/icons/totalvacancy.svg" alt="Notification" />}
                    />
                </Grid>
                <Grid item lg={4} md={6} sm={10} xs={12}>
                    <DashboardSideImage
                        primary={maleCount}
                        secondarySub="Total Male Students"
                        color="#015ca1"
                        // navigateUrl="/hostel/reports"
                        bgImage={<img src="/assets/images/icons/malestudents.svg" alt="Notification" />}
                    />
                </Grid>
                <Grid item lg={4} md={6} sm={10} xs={12}>
                    <DashboardSideImage
                        primary={femaleCount}
                        secondarySub="Total Female Students"
                        color="#015ca1"
                        // navigateUrl="/hostel/reports"
                        bgImage={<img src="/assets/images/icons/female.svg" alt="Notification" />}
                    />
                </Grid>
            </Grid>
            <Grid container md={12} xs={12} sx={{ display: 'flex' }} mb={1}>
                <Grid item md={12} xs={12}>
                    <MainCard>
                        <FormControl fullWidth>
                            <InputLabel id="Country-select">Buildings</InputLabel>
                            <Select
                                fullWidth
                                id="buildings"
                                name="buildings"
                                label="Buildings *"
                                required
                                onChange={(event) => handleBuildingSelection(event)}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {hostelBuildingsList.map((building) => (
                                    <MenuItem value={building.id}>{building.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </MainCard>
                </Grid>
            </Grid>
            <Grid container md={12} xs={12} sx={{ display: 'flex' }} spacing={2}>
                {isLoading ? (
                    <Grid item md={8.5} xs={12}>
                        <HostelReports />
                    </Grid>
                ) : buildingId === '' ? (
                    <Grid item md={8.5} xs={12}>
                        <HostelReports />
                    </Grid>
                ) : (
                    <Grid item md={8.5} xs={12}>
                        {isBuildingSelected && (
                            <MainCard>
                                <Box sx={{ typography: 'body1' }}>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList variant="scrollable" onChange={handleFloorChange} aria-label="lab API tabs example">
                                                {Array.from({ length: floorNumber }, (_, i) => (
                                                    <Tab
                                                        label={`${i === 0 ? 'Ground' : i} Floor`}
                                                        value={i}
                                                        onClick={() => {
                                                            fetchFloorData(i + 1);
                                                            setCurrentFloor(i + 1);
                                                        }}
                                                    />
                                                ))}
                                            </TabList>
                                        </Box>
                                        {Array.from({ length: floorNumber }, (_, i) => (
                                            <TabPanel value={i}>
                                                <Grid container spacing={gridSpacing}>
                                                    {floorData.map((floor) => (
                                                        <Grid item md={12} xs={12}>
                                                            <RoomCard floorData={floor} />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </TabPanel>
                                        ))}
                                    </TabContext>
                                </Box>
                            </MainCard>
                        )}
                    </Grid>
                )}
                <Grid
                    item
                    md={3.5}
                    xs={12}
                    sx={{
                        height: 600,
                        overflow: 'auto'
                    }}
                >
                    <MainCard title="Hostel Category" content={false}>
                        <List
                            component="nav"
                            aria-label="main mailbox folders"
                            sx={{
                                '& svg': {
                                    width: 32,
                                    my: -0.75,
                                    ml: -0.75,
                                    mr: 0.75
                                }
                            }}
                        >
                            {categoryStats.length > 0 &&
                                categoryStats?.map((hostel, index) => (
                                    <ListItemButton>
                                        <ListItemText
                                            primary={
                                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                    <Grid
                                                        container
                                                        md={12}
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            p: 1,
                                                            mt: -1,
                                                            borderBottom: '2px solid #EFEFEF',
                                                            borderRadius: '10px',
                                                            ...(selectedCategory === hostel.category && { backgroundColor: '#ede7f6' })
                                                        }}
                                                    >
                                                        <Grid item md={12} sx={{ display: 'flex', m: 1 }}>
                                                            <Typography variant="h4">{hostel?.category}</Typography>
                                                        </Grid>
                                                        <Grid md={5} sx={{ m: 1 }}>
                                                            <span>Total Capacity: </span>
                                                            <Typography sx={successSX}>{hostel?.capacity}</Typography>
                                                        </Grid>
                                                        <Grid md={5} sx={{ m: 1 }}>
                                                            <span>Total Vacancy: </span>
                                                            <Typography sx={errorSX}>{hostel?.vacancy}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Stack>
                                            }
                                        />
                                    </ListItemButton>
                                ))}
                        </List>
                    </MainCard>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
