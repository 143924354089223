import { styled } from '@mui/material/styles';
import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@mui/material';

// assets
import { useNavigate } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 12,
    margin: -2,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ notification, handleClose, clearNotifications }) => {
    const navigate = useNavigate();
    const convertEpochToGMT = (epochTime) => {
        const date = new Date(epochTime * 1000);
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        return date.toLocaleString('en-US', options);
    };
    return (
        <Box>
            {notification?.map((elem) => (
                <List
                    key={elem?.id}
                    onClick={() => {
                        if (elem?.url) {
                            navigate(elem?.url);
                            clearNotifications([elem?.id]);
                            handleClose();
                        }
                    }}
                >
                    <ListItemWrapper>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: -1, mb: 0 }}>
                            <Typography variant="caption" display="block">
                                {convertEpochToGMT(elem?.timestamp)}
                            </Typography>
                        </Box>
                        <ListItem alignItems="center" sx={{ display: 'flex', justifyContent: 'space-between', mb: -1, pb: 0 }}>
                            <ListItemAvatar>
                                <Avatar alt="Rate Book" src="/assets/library-icons/book.jpg" />
                            </ListItemAvatar>
                            <Box sx={{ flexGrow: 1 }}>
                                <ListItemText primary={elem?.title} />
                                <Box sx={{ mt: -1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Tooltip title={elem?.message || ''} arrow>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                WebkitLineClamp: 2,
                                                maxWidth: 'calc(100% - 40px)',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {elem?.message}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title="Clear">
                                        <IconButton onClick={() => clearNotifications([elem?.id])}>
                                            <HighlightOffIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </ListItem>
                    </ListItemWrapper>
                </List>
            ))}
        </Box>
    );
};

export default NotificationList;

// /* eslint-disable array-callback-return */
// // material-ui
// import { useTheme, styled } from '@mui/material/styles';
// import {
//     Avatar,
//     Button,
//     Card,
//     CardContent,
//     Chip,
//     Divider,
//     Grid,
//     List,
//     ListItem,
//     ListItemAvatar,
//     ListItemSecondaryAction,
//     ListItemText,
//     Stack,
//     Typography
// } from '@mui/material';

// // assets
// import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
// import User1 from 'assets/images/users/user-round.svg';
// import { useEffect, useState } from 'react';
// import axios from 'axios';

// // styles
// const ListItemWrapper = styled('div')(({ theme }) => ({
//     cursor: 'pointer',
//     padding: 16,
//     '&:hover': {
//         background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
//     },
//     '& .MuiListItem-root': {
//         padding: 0
//     }
// }));

// // ==============================|| NOTIFICATION LIST ITEM ||============================== //

// const NotificationList = () => {
//     const theme = useTheme();
//     const [notification, setNotification] = useState({});
//     useEffect(() => {
//         axios
//             .get(`/v1/user/notifications`, {
//                 headers: {
//                     Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
//                     'Content-Type': 'application/json'
//                 }
//             })
//             .then((result) => {
//                 console.log(result, '<<<<<<<<<<<<<<result');
//                 result.data.data.map((notify) => {
//                     setNotification(notify);
//                 });
//             });
//     }, []);

//     const chipSX = {
//         height: 24,
//         padding: '0 6px'
//     };
//     const chipErrorSX = {
//         ...chipSX,
//         color: theme.palette.orange.dark,
//         backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
//         marginRight: '5px'
//     };

//     const chipWarningSX = {
//         ...chipSX,
//         color: theme.palette.warning.dark,
//         backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light
//     };

//     const chipSuccessSX = {
//         ...chipSX,
//         color: theme.palette.success.dark,
//         backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
//         height: 28
//     };

//     return (
//         <List
//             sx={{
//                 width: '100%',
//                 maxWidth: 330,
//                 py: 0,
//                 borderRadius: '10px',
//                 [theme.breakpoints.down('md')]: {
//                     maxWidth: 300
//                 },
//                 '& .MuiListItemSecondaryAction-root': {
//                     top: 22
//                 },
//                 '& .MuiDivider-root': {
//                     my: 0
//                 },
//                 '& .list-container': {
//                     pl: 7
//                 }
//             }}
//         >
//             <ListItemWrapper>
//                 <ListItem alignItems="center">
//                     <ListItemAvatar>
//                         <Avatar alt="John Doe" src={User1} />
//                     </ListItemAvatar>
//                     <ListItemText primary="John Doe" />
//                     <ListItemSecondaryAction>
//                         <Grid container justifyContent="flex-end">
//                             <Grid item xs={12}>
//                                 <Typography variant="caption" display="block" gutterBottom>
//                                     2 min ago
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                     </ListItemSecondaryAction>
//                 </ListItem>
//                 <Grid container direction="column" className="list-container">
//                     <Grid item xs={12} sx={{ pb: 2 }}>
//                         <Typography variant="subtitle2">{notification.message}</Typography>
//                     </Grid>
//                     {/* <Grid item xs={12}>
//                         <Grid container>
//                             <Grid item>
//                                 <Chip label="Unread" sx={chipErrorSX} />
//                             </Grid>
//                             <Grid item>
//                                 <Chip label="New" sx={chipWarningSX} />
//                             </Grid>
//                         </Grid>
//                     </Grid> */}
//                 </Grid>
//             </ListItemWrapper>
//             <Divider />
//             {/* <ListItemWrapper>
//                 <ListItem alignItems="center">
//                     <ListItemAvatar>
//                         <Avatar
//                             sx={{
//                                 color: theme.palette.success.dark,
//                                 backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
//                                 border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
//                                 borderColor: theme.palette.success.main
//                             }}
//                         >
//                             <IconBuildingStore stroke={1.5} size="20px" />
//                         </Avatar>
//                     </ListItemAvatar>
//                     <ListItemText primary={<Typography variant="subtitle1">Store Verification Done</Typography>} />
//                     <ListItemSecondaryAction>
//                         <Grid container justifyContent="flex-end">
//                             <Grid item xs={12}>
//                                 <Typography variant="caption" display="block" gutterBottom>
//                                     2 min ago
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                     </ListItemSecondaryAction>
//                 </ListItem>
//                 <Grid container direction="column" className="list-container">
//                     <Grid item xs={12} sx={{ pb: 2 }}>
//                         <Typography variant="subtitle2">We have successfully received your request.</Typography>
//                     </Grid>
//                     <Grid item xs={12}>
//                         <Grid container>
//                             <Grid item>
//                                 <Chip label="Unread" sx={chipErrorSX} />
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </ListItemWrapper> */}
//             <Divider />
//             {/* <ListItemWrapper>
//                 <ListItem alignItems="center">
//                     <ListItemAvatar>
//                         <Avatar
//                             sx={{
//                                 color: theme.palette.primary.dark,
//                                 backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
//                                 border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
//                                 borderColor: theme.palette.primary.main
//                             }}
//                         >
//                             <IconMailbox stroke={1.5} size="20px" />
//                         </Avatar>
//                     </ListItemAvatar>
//                     <ListItemText primary={<Typography variant="subtitle1">Check Your Mail.</Typography>} />
//                     <ListItemSecondaryAction>
//                         <Grid container justifyContent="flex-end">
//                             <Grid item>
//                                 <Typography variant="caption" display="block" gutterBottom>
//                                     2 min ago
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                     </ListItemSecondaryAction>
//                 </ListItem>
//                 <Grid container direction="column" className="list-container">
//                     <Grid item xs={12} sx={{ pb: 2 }}>
//                         <Typography variant="subtitle2">All done! Now check your inbox as you&apos;re in for a sweet treat!</Typography>
//                     </Grid>
//                     <Grid item xs={12}>
//                         <Grid container>
//                             <Grid item>
//                                 <Button variant="contained" disableElevation endIcon={<IconBrandTelegram stroke={1.5} size="20px" />}>
//                                     Mail
//                                 </Button>
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </ListItemWrapper> */}
//             <Divider />
//             {/* <ListItemWrapper>
//                 <ListItem alignItems="center">
//                     <ListItemAvatar>
//                         <Avatar alt="John Doe" src={User1} />
//                     </ListItemAvatar>
//                     <ListItemText primary={<Typography variant="subtitle1">John Doe</Typography>} />
//                     <ListItemSecondaryAction>
//                         <Grid container justifyContent="flex-end">
//                             <Grid item xs={12}>
//                                 <Typography variant="caption" display="block" gutterBottom>
//                                     2 min ago
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                     </ListItemSecondaryAction>
//                 </ListItem>
//                 <Grid container direction="column" className="list-container">
//                     <Grid item xs={12} sx={{ pb: 2 }}>
//                         <Typography component="span" variant="subtitle2">
//                             Uploaded two file on &nbsp;
//                             <Typography component="span" variant="h6">
//                                 21 Jan 2020
//                             </Typography>
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={12}>
//                         <Grid container>
//                             <Grid item xs={12}>
//                                 <Card
//                                     sx={{
//                                         backgroundColor:
//                                             theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light
//                                     }}
//                                 >
//                                     <CardContent>
//                                         <Grid container direction="column">
//                                             <Grid item xs={12}>
//                                                 <Stack direction="row" spacing={2}>
//                                                     <IconPhoto stroke={1.5} size="20px" />
//                                                     <Typography variant="subtitle1">demo.jpg</Typography>
//                                                 </Stack>
//                                             </Grid>
//                                         </Grid>
//                                     </CardContent>
//                                 </Card>
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </ListItemWrapper> */}
//             <Divider />
//             {/* <ListItemWrapper>
//                 <ListItem alignItems="center">
//                     <ListItemAvatar>
//                         <Avatar alt="John Doe" src={User1} />
//                     </ListItemAvatar>
//                     <ListItemText primary={<Typography variant="subtitle1">John Doe</Typography>} />
//                     <ListItemSecondaryAction>
//                         <Grid container justifyContent="flex-end">
//                             <Grid item xs={12}>
//                                 <Typography variant="caption" display="block" gutterBottom>
//                                     2 min ago
//                                 </Typography>
//                             </Grid>
//                         </Grid>
//                     </ListItemSecondaryAction>
//                 </ListItem>
//                 <Grid container direction="column" className="list-container">
//                     <Grid item xs={12} sx={{ pb: 2 }}>
//                         <Typography variant="subtitle2">It is a long established fact that a reader will be distracted</Typography>
//                     </Grid>
//                     <Grid item xs={12}>
//                         <Grid container>
//                             <Grid item>
//                                 <Chip label="Confirmation of Account." sx={chipSuccessSX} />
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </ListItemWrapper> */}
//         </List>
//     );
// };

// export default NotificationList;
