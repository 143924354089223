import React, { useEffect, useState } from 'react';
import useMessageDispatcher from 'hooks/useMessageDispatcher';
import axios from 'axios';
import { Box, Typography, Radio, FormControlLabel, Rating, Divider, TextField, Grid, Button, IconButton, Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import { ArrowBack } from '@mui/icons-material';

const headers = {
    headers: {
        Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
        'Content-Type': 'application/json'
    }
};

const ViewStaffResponse = ({ formId, staffId }) => {
    const [staffResponse, setStaffResponse] = useState([]);
    const [feedback, setFeedback] = useState('');
    // const location = useLocation();
    // const { formId, staffId, staffName } = location?.state;
    const showMessage = useMessageDispatcher();

    const viewResponses = async () => {
        try {
            const response = await axios.get(`/v1/appraisal/list-answer?formId=${formId}&staffId=${staffId}`, headers);
            if (response?.status === 200) {
                setStaffResponse(response?.data?.data);
            }
        } catch (error) {
            if (!error.response) {
                showMessage({ message: error.message, type: 'error' });
            } else {
                showMessage({ message: error.response?.data?.Msg, type: 'error' });
            }
        }
    };

    useEffect(() => {
        viewResponses();
    }, []);

    const renderAnswer = (question) => {
        const answer = question?.answer;
        const questionType = question?.question?.questionType;
        const options = question?.question?.options;

        switch (questionType) {
            case 'TEXT':
                return (
                    <Box sx={{ paddingLeft: 2 }}>
                        <Typography variant="body1">{answer}</Typography>
                    </Box>
                );

            case 'RATTING':
                return (
                    <Box sx={{ paddingLeft: 2 }}>
                        <Typography variant="body1">
                            <Rating value={answer || 0} precision={0.5} readOnly />
                        </Typography>
                    </Box>
                );

            case 'RADIO':
                return (
                    <Box sx={{ paddingLeft: 2 }}>
                        <Typography variant="body1">
                            {options?.map((option, index) => (
                                <FormControlLabel key={index} value={option.option} control={<Radio />} label={option.option} disabled />
                            ))}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: 1, color: '#00326D', fontWeight: 600 }}>
                            Selected Answer: <span style={{ fontStyle: 'italic', color: 'green' }}>{answer}</span>
                        </Typography>
                    </Box>
                );

            default:
                return null;
        }
    };

    return (
        <>
            {/* <MainCard sx={{ mb: 1 }}>
                <Box display="flex" alignItems="center">
                    <Tooltip title="Go Back">
                        <IconButton onClick={() => navigate(-1)}>
                            <ArrowBack sx={{ color: '#00326D' }} />
                        </IconButton>
                    </Tooltip>
                    <Typography sx={{ color: '#00326D', fontSize: '16px', fontWeight: 600 }}>{staffName}&apos;s Response</Typography>
                </Box>
            </MainCard> */}
            <>
                <Box sx={{ padding: 3, backgroundColor: '#f5f5f5' }}>
                    {staffResponse?.map((questionData, index) => (
                        <Box key={index} sx={{ marginBottom: 1, padding: 2, backgroundColor: '#fff', borderRadius: '8px', boxShadow: 1 }}>
                            <Typography variant="h6" sx={{ color: '#00326D', marginBottom: 1 }}>
                                {index + 1}. {questionData?.question?.question}
                            </Typography>
                            {renderAnswer(questionData)}
                            <Divider sx={{ marginTop: 2 }} />
                        </Box>
                    ))}
                </Box>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Comment"
                            placeholder="Add feedback..."
                            multiline
                            rows={4}
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            error={feedback && feedback.length < 40}
                            helperText={feedback && feedback.length < 40 ? 'Feedback must be at least 40 characters' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                        <Button disabled={!feedback} variant="contained" onClick={() => console.log('Feedback added')}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </>
        </>
    );
};

export default ViewStaffResponse;

// import React, { useEffect, useState } from 'react';
// import useMessageDispatcher from 'hooks/useMessageDispatcher';
// import axios from 'axios';
// import { Box, Typography, Rating } from '@mui/material';

// const headers = {
//     headers: {
//         Authorization: `Bearer ${window.localStorage.getItem('serviceToken')}`,
//         'Content-Type': 'application/json'
//     }
// };

// const ViewStaffResponse = ({ formId, staffId }) => {
//     const [staffResponse, setStaffResponse] = useState([]);
//     const showMessage = useMessageDispatcher();

//     const viewResponses = async () => {
//         try {
//             const response = await axios.get(`/v1/appraisal/list-answer?formId=${formId}&staffId=${staffId}`, headers);
//             if (response?.status === 200) {
//                 setStaffResponse(response?.data?.data);
//             }
//         } catch (error) {
//             if (!error.response) {
//                 showMessage({ message: error.message, type: 'error' });
//             } else {
//                 showMessage({ message: error.response?.data?.Msg, type: 'error' });
//             }
//         }
//     };

//     const renderAnswer = (question, answer) => {
//         switch (question?.questionType) {
//             case 'TEXT':
//                 return <Typography>{answer}</Typography>;

//             case 'RATTING':
//                 return <Rating name={`rating-${question?.id}`} precision={0.5} value={answer || null} readOnly />;

//             case 'RADIO':
//                 return (
//                     <Box>
//                         {question?.options?.map((option, index) => (
//                             <Box key={index}>
//                                 <Typography>{option?.option}</Typography>
//                                 {answer === option?.option && <Typography sx={{ fontWeight: 'bold' }}>Selected: {answer}</Typography>}
//                             </Box>
//                         ))}
//                     </Box>
//                 );

//             default:
//                 return null;
//         }
//     };

//     useEffect(() => {
//         viewResponses();
//     }, []);

//     return (
//         <Box sx={{ padding: '20px' }}>
//             {staffResponse?.map((response, i) => (
//                 <Box key={response?.question?.id} sx={{ marginBottom: '20px' }}>
//                     <Typography variant="h6" gutterBottom>
//                         {i + 1}. {response?.question?.question}
//                     </Typography>
//                     {renderAnswer(response?.question, response?.answer)}
//                 </Box>
//             ))}
//         </Box>
//     );
// };

// export default ViewStaffResponse;
