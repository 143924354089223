import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const RegistrationDetail = Loadable(lazy(() => import('views/admissionregister/index')));
const BasicTab = Loadable(lazy(() => import('views/admissionregister/BasicTab')));
const StudentEdit = Loadable(lazy(() => import('views/admissionregister/Edit')));
const StudentDetails = Loadable(lazy(() => import('views/admissionregister/details/index')));
// const StudentAddmission = Loadable(lazy(() => import('views/addmissionform/index')));
const BasicInfoTab = Loadable(lazy(() => import('views/addmissionform/BasicInfoTab')));
const StudentAddmissionList = Loadable(lazy(() => import('views/StudentAdmissionList/Index')));
// eslint-disable-next-line import/no-unresolved
const StudentAddmissionProfile = Loadable(lazy(() => import('views/StudentAdmissionList/StudentAdmissionProfile')));
const StudentAddmissionFinal = Loadable(lazy(() => import('views/student/admissionstudentList/index')));
const StudentDashBoard = Loadable(lazy(() => import('views/student/student-details/Index')));
const Assignments = Loadable(lazy(() => import('views/student/assignments')));
const UploadAStudentssignment = Loadable(lazy(() => import('views/student/assignments')));
const AssignmentDetail = Loadable(lazy(() => import('views/student/assignments/submissions-and-results/AssignmentDetail')));
const StartAssignment = Loadable(lazy(() => import('views/student/assignments/solve-assignment/StartAssignment')));
const Resources = Loadable(lazy(() => import('views/student/resources')));
const StudentAlumni = Loadable(lazy(() => import('views/alumni/AlumniList')));
const OfflineRegistration = Loadable(lazy(() => import('views/offlineregistration/index')));
const BasicInfoTabOfline = Loadable(lazy(() => import('views/offlineadmission/BasicInfoTab')));
const InternalAdmissionForm = Loadable(lazy(() => import('views/offlineinternaladmission/InternalAdmissionForm')));
const InternalAdmissionProfile = Loadable(lazy(() => import('views/offlineinternaladmission/AdmissionProfile')));
const OfflineStudentProfile = Loadable(lazy(() => import('views/offlineadmission/Profile')));
// const InternalAdmission = Loadable(lazy(() => import('views/offlineadmission/InternalAdmission')));
const SdtudentPromotion = Loadable(lazy(() => import('views/application/student-permotion/index')));
const SdtudentAttendance = Loadable(lazy(() => import('views/application/StudentAttendance/Attenance')));
const SdtudentAttendanceprofile = Loadable(lazy(() => import('views/application/StudentAttendance/Attenance')));
const AssignmentList = Loadable(lazy(() => import('views/student/student-details/dashboard/profile/assignment/AssignmentList')));
const Assignment = Loadable(lazy(() => import('views/student/student-details/dashboard/profile/assignment/Assignment')));
const FeesPayment = Loadable(lazy(() => import('views/studentadmissionProfile/AdmissionFeesPage')));
const ChangeDegree = Loadable(lazy(() => import('views/student/changedegree/ChangeDegree')));
const DegreeTransferHistory = Loadable(lazy(() => import('views/student/changedegree/DegreeTransferHistory')));
const UploadAssignment = Loadable(lazy(() => import('views/assignment-new/UploadAssignment')));
const StudentSummary = Loadable(lazy(() => import('views/application/studentsummary/StudentSummary')));
const MyAttendance = Loadable(lazy(() => import('views/student/student-details/dashboard/MyAttendance')));
const MyAttendanceReport = Loadable(lazy(() => import('views/student/student-details/dashboard/FilterSection')));
const StudentFaceRegistered = Loadable(lazy(() => import('views/application/student-accounts/StudentFaceRegister')));
const StudentEnrollmentReport = Loadable(lazy(() => import('views/student/student-enrollments')));
const roles = ['ROLE_STUDENT', 'ROLE_STUDENT_ADMISSION', 'ROLE_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN', 'ROLE_ADMIN'];

const StudentRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/student/registration',
            element: <BasicTab />
        },
        {
            path: '/student/registration/detail',
            element: <RegistrationDetail />
        },
        {
            path: '/student/edit',
            element: <StudentEdit />
        },
        {
            path: '/student/details',
            element: <StudentDetails />
        },
        {
            path: '/student/addmission/:id',
            element: <BasicInfoTab />
        },
        {
            path: '/student/admission/list',
            element: <StudentAddmissionList />
        },
        // {
        //     path: '/student/addmission/List',
        //     element: <StudentAddmissionList />
        // },
        {
            path: '/student/addmission/profile/:id/:courseDetailId',
            element: <StudentAddmissionProfile />
        },
        {
            path: '/student/addmission/final/list',
            element: <StudentAddmissionFinal />
        },
        {
            path: '/student/dashboard',
            element: <StudentDashBoard />
        },
        {
            path: '/student/dashboard/studentattendanceReport',
            element: <StudentDashBoard />
        },
        {
            path: '/offline/registration',
            element: <OfflineRegistration />
        },
        {
            path: '/offline/student/profile',
            element: <OfflineStudentProfile />
        },
        {
            path: '/offline/admission/:cId/:uId',
            element: <BasicInfoTabOfline />
        },
        {
            path: '/internal/admission/:cId/:uId',
            element: <InternalAdmissionForm />
        },
        {
            path: '/internal/admission/profile/:cId/:uId',
            element: <InternalAdmissionProfile />
        },
        {
            path: '/student/promotion',
            element: <SdtudentPromotion />
        },
        {
            path: '/student/attendances/:id',
            element: <SdtudentAttendance />
        },
        {
            path: '/student/attendances',
            element: <SdtudentAttendance />
        },
        {
            path: '/student/assignments',
            element: <Assignments />
        },
        {
            path: '/upload/assignments/:subjectName',
            element: <UploadAStudentssignment />
        },
        {
            path: '/student/assignments/:assignmentId/:courseDetailId',
            element: <UploadAssignment />
        },
        {
            path: '/student/assignment-details/:id/:studentNamee/:studentIds',
            element: <AssignmentDetail />
        },
        {
            path: '/student/start-assignment/:id/:answerSheetId',
            element: <StartAssignment />
        },
        {
            path: '/student/resources',
            element: <Resources />
        },
        {
            path: '/student/dashboard/allassignments',
            element: <AssignmentList />
        },
        {
            path: '/student/dashboard/assignment/:id',
            element: <Assignment />
        },
        {
            path: '/student/alumni',
            element: <StudentAlumni />
        },
        {
            path: '/payment/fees',
            element: <FeesPayment />
        },
        {
            path: '/change/degree',
            element: <ChangeDegree />
        },
        {
            path: '/degree/transfer/history',
            element: <DegreeTransferHistory />
        },
        {
            path: '/student/summary/:id',
            element: <StudentSummary />
        },
        {
            path: '/student/my-attendance',
            element: <MyAttendance />
        },
        {
            path: '/student/my-attendance/report/:subjectId/:subjectName',
            element: <MyAttendanceReport />
        },
        {
            path: '/student/face/register',
            element: <StudentFaceRegistered />
        },
        {
            path: '/student/enrollment-report',
            element: <StudentEnrollmentReport />
        }
    ]
};
export default StudentRoutes;
