import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MobileMenu from 'views/application/institute/mobile';

// Department
// const MenuAccess = Loadable(lazy(() => import('views/application/menu/Menus')));
const MenuAccessAdd = Loadable(lazy(() => import('views/application/menu/index')));
const MenuAccessEdit = Loadable(lazy(() => import('views/application/menu/MenuAccessEdit')));
const MenuAdd = Loadable(lazy(() => import('views/application/menus/index')));
const SubMenuAdd = Loadable(lazy(() => import('views/application/menus/MenusAdd')));
const SubMenuEdit = Loadable(lazy(() => import('views/application/menus/SubMenuEdit')));
const GroupAdd = Loadable(lazy(() => import('views/application/menus/SubMenuEdit')));
const LessionPlan = Loadable(lazy(() => import('views/application/lessionPlan')));
const LessionPlanPdf = Loadable(lazy(() => import('views/application/lessionPlan/downloadpdf/index')));
const LessionPlanEdit = Loadable(lazy(() => import('views/application/lessionPlan/LessonPlanEdit')));
const LessionPlanView = Loadable(lazy(() => import('views/application/lessionPlan/LessonPlanView')));
const LessionPlanShow = Loadable(lazy(() => import('views/application/lessionPlan/LessonPlanShow')));
const LessionPlanTracker = Loadable(lazy(() => import('views/application/lessionPlan/lession-plan-tracker/index')));
const ClassAllotedReport = Loadable(lazy(() => import('views/application/report/lessonplanreport/ClassAllotedReport')));
const ClassTakenReportPdf = Loadable(lazy(() => import('views/application/report/lessonplanreport/class-taken-reports-pdf')));
const MenuSettings = Loadable(lazy(() => import('views/application/institute/menusettings/Add')));

const roles = ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN', 'ROLE_COLLEGE_ADMIN'];

const InstituteRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // {
        //     path: '/institute/menus/access',
        //     element: <MenuAccess />
        // },
        {
            path: '/institute/menus/access/add',
            element: <MenuAccessAdd />
        },
        {
            path: '/institute/menus/access/edit/:id',
            element: <MenuAccessEdit />
        },
        {
            path: '/institute/menus/list',
            element: <MenuAdd />
        },
        {
            path: '/institute/submenu/add',
            element: <SubMenuAdd />
        },
        {
            path: '/institute/submenu/edit',
            element: <SubMenuEdit />
        },
        {
            path: '/institute/group/add',
            element: <GroupAdd />
        },
        {
            path: '/institute/lessionplan/add',
            element: <LessionPlan />
        },
        {
            path: '/institute/lessionplan/pdf',
            element: <LessionPlanPdf />
        },
        {
            path: '/institute/lessionplan/edit/:id',
            element: <LessionPlanEdit />
        },
        {
            path: '/institute/lessionplan/view/:id/:subjectId/:sessionId',
            element: <LessionPlanView />
        },
        {
            path: '/institute/lessionplan/:id',
            element: <LessionPlanShow />
        },
        {
            path: '/lession/plan/tracker/:id',
            element: <LessionPlanTracker />
        },
        {
            path: '/mobile/menu/:instituteId',
            element: <MobileMenu />
        },
        {
            path: '/menu/setting/:instituteId',
            element: <MenuSettings />
        },
        {
            path: '/class/allotment/report',
            element: <ClassAllotedReport />
        },
        {
            path: '/class/allotted-taken/report/pdf',
            element: <ClassTakenReportPdf />
        }
    ]
};

export default InstituteRoutes;
