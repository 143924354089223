import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const DepartmentBuilding = Loadable(lazy(() => import('views/settings/department-building/index')));
const BuildingFloors = Loadable(lazy(() => import('views/settings/building-floors')));
const FloorRooms = Loadable(lazy(() => import('views/settings/floor-rooms')));
const TemplateEmailAdd = Loadable(lazy(() => import('views/application/setting/index')));
const AttendanceSetting = Loadable(lazy(() => import('views/settings/attendance-setup/index')));
const AttendanceSetupEditor = Loadable(lazy(() => import('views/settings/attendance-setup/EditAttendanceSetup')));
const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];
const SecurityGuardAdd = Loadable(lazy(() => import('views/settings/security-guard/index')));

const SettingsRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/department/building',
            element: <DepartmentBuilding />
        },
        {
            path: '/department/building/floors',
            element: <BuildingFloors />
        },
        {
            path: '/department/building/floors/rooms',
            element: <FloorRooms />
        },
        {
            path: '/attendance/setup',
            element: <AttendanceSetting />
        },
        {
            path: '/attendance/setup/edit/:id',
            element: <AttendanceSetupEditor />
        },
        {
            path: '/template/email/add',
            element: <TemplateEmailAdd />
        },
        {
            path: '/securityguard/list',
            element: <SecurityGuardAdd />
        }
    ]
};
export default SettingsRoutes;
