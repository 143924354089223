import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const roles = ['ROLE_STUDENT', 'ROLE_STUDENT_ADMISSION', 'ROLE_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN', 'ROLE_ADMIN'];
const BusStoppage = Loadable(lazy(() => import('views/application/transports/busstoppage/index')));
const TransportOccupancyList = Loadable(lazy(() => import('views/dashboard/transportOccupencies/index')));
const StudentListByBusStop = Loadable(lazy(() => import('views/dashboard/transportOccupencies/TransportStudentList')));
const TransportAllocateStudent = Loadable(lazy(() => import('views/application/transports/student-allocation/index')));
const TransportOccupancyReport = Loadable(lazy(() => import('views/application/transports/transportoccupancy/TransportOccupancyList')));
const TransportDiscontinueList = Loadable(lazy(() => import('views/application/transports/transportoccupancy/TransportDiscontinueList')));

const TransportsRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/bus/stoppage',
            element: <BusStoppage />
        },
        {
            path: '/transport/allocate/student',
            element: <TransportAllocateStudent />
        },
        {
            path: '/transport/occupencies',
            element: <TransportOccupancyList />
        },
        {
            path: '/transport/studentlist/:rootId',
            element: <StudentListByBusStop />
        },

        {
            path: '/transport/occupancy/report',
            element: <TransportOccupancyReport />
        },
        {
            path: '/transport/discontinue/list',
            element: <TransportDiscontinueList />
        }
    ]
};
export default TransportsRoutes;
