import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const VisitorAppointmentApproval = Loadable(lazy(() => import('views/application/visitors')));
const AppointmentReport = Loadable(lazy(() => import('views/application/visitors/Reports/AppointmentReport')));

const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const VisitorRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/visitor/appointment/approval-request',
            element: <VisitorAppointmentApproval />
        },
        {
            path: '/visitor/report',
            element: <AppointmentReport />
        }
    ]
};
export default VisitorRoutes;
